<template>
  <div class="main-content-group-school">
    <b-card title="Quản lý tổ">
      <div class="top-page-custom-group mt-2">
        <b-row>
          <b-col lg="4">
            <div class="search-school-block-input-select">
              <b-form-group>
                <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
                <v-select placeholder="Chọn phòng ban" @input="getListSchoolBlock" v-model="modelSchoolGroupSearch.departmentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id">
                   <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
                </v-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="search-school-block-input-select">
              <b-form-group>
                <label class="font-weight-bold font-size-label-custom">Khối</label>
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" @input="getListAllSchoolGroup" placeholder="Chọn khối" v-model="modelSchoolGroupSearch.parentId" :options="listSchoolBlock" label="organizationBranchName" :reduce="(department) => department.id">
                  <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
                </v-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="search-school-block-input">
              <b-form-input v-model="modelSchoolGroupSearch.name" @keyup.enter="getListAllSchoolGroup" type="text" placeholder="Nhập tên tổ để tìm kiếm" />
            </div>
          </b-col>
          <b-col lg="2">
            <div class="acction-search-school-block">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="getListAllSchoolGroup"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="body-page-group">
        <b-row>
          <b-col lg="12">
            <div class="acction-add-school-group mb-2">
              <b-button @click="getFormCreateSchoolGroup"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Thêm mới</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <div class="table-school-block-data">
              <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
              <b-table
                :per-page="perrPage"
                :current-page="currentPage"
                :fields="fieldSchoolGroup"
                :items="listSchoolGroup">
                <template #cell(index)="data">
                  <span>{{data.index + 1}}</span>
                </template>
                <template #cell(hanhdong)="data">
                  <b-button @click="getFormUpdateSchoolGroup(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button v-b-tooltip.hover.top="'Xóa'"
                            variant="flat-danger"
                            class="btn-icon" @click="deleteSchoolGroup(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                :total-rows="rows"
                class="float-right mt-2"
                v-model="currentPage"
                :per-page="perrPage"
                hide-goto-end-buttons>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-school-group"
             size="sm"
             hide-header
             hide-footer>
      <div class="title-header-modal">
        <h4 class="card-title">{{isSchoolGroup? 'Cập nhật' : 'Tạo mới'}} tổ</h4>
      </div>
      <validation-observer ref="modelSchoolBlock">
        <div class="main-modal-school-block">
          <b-form-group v-if="!isSchoolGroup">
            <validation-provider  #default="{ errors }" name="Phòng ban" rules="required">
              <label class="font-weight-bold">Phòng ban</label>
              <v-select class="custom-v-select" menu-props="auto"  placeholder="Chọn phòng ban" @input="getListSchoolBlockFormModal" v-model="modelSchoolGroup.departmentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id">
                <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="!isSchoolGroup">
            <validation-provider  #default="{ errors }" name="Khối" rules="required">
              <label class="font-weight-bold">Khối</label>
              <v-select class="custom-v-select" menu-props="auto" :class="errors.length > 0 ? 'is-invalid':null" placeholder="Chọn khối" v-model="modelSchoolGroup.parentId" :options="listSchoolBlock" label="organizationBranchName" :reduce="(department) => department.id"></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="isSchoolGroup">
            <label class="font-weight-bold">Phòng ban</label>
            <v-select placeholder="Chọn phòng ban" disabled @input="getListSchoolBlockFormModal" v-model="modelSchoolGroup.departmentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id"></v-select>
          </b-form-group>
          <b-form-group v-if="isSchoolGroup">
            <label class="font-weight-bold">Khối</label>
            <v-select disabled placeholder="Chọn khối" v-model="modelSchoolGroup.parentId" :options="listSchoolBlock" label="organizationBranchName" :reduce="(department) => department.id">
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
          </b-form-group>
          <b-form-group>
            <validation-provider #default="{ errors }" name="Tên tổ" rules="required">
              <label class="font-weight-bold">Tên tổ <span class="text-danger">(*)</span></label>
              <b-form-input placeholder="Nhập tên tổ" v-model="modelSchoolGroup.organizationBranchName"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="footer-modal-school-group">
          <b-button v-if="!isSchoolGroup" class="mr-1" @click="addSchoolGroup"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Tạo mới</span>
          </b-button>
          <b-button v-if="isSchoolGroup" class="mr-1" @click="updateSchoolGroup"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Cập nhật</span>
          </b-button>
          <b-button @click="closeModal"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
          >
            <feather-icon icon="XIcon" />
            <span class="align-middle">Đóng</span>
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BModal, BFormGroup, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormGroup,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      perrPage: 10,
      currentPage: 1,
      isSchoolGroup: false,
      listPerPageSchoolGroup: [3, 5, 10],
      required,
      totalCount: 0,
      fieldSchoolGroup: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-school-block-stt',
        },
        {
          key: 'organizationBranchName', label: 'Tên tổ',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-school-block', tdClass: 'custom-th-action',
        },
      ],
      listSchoolGroup: [
        3, 5, 10,
      ],
      idSchoolBlock: 1,
      idDepartment: null,
      modelSchoolGroup: {
        departmentId: null,
        groupId: null,
        parentId: null,
        organizationBranchName: '',
      },
      idSchoolGroup: null,
      textDepartment: '',
      textSchoolGroup: '',
      listSchoolBlock: [],
      listDepartment: [],
      modelSchoolGroupSearch: {
        departmentId: '',
        parentId: null,
        name: '',
        number: 2,
      },
    }
  },
  created() {
    this.getListAllSchoolGroup()
    this.getListAllDepartment()
  },
  methods: {
    getListAllSchoolGroup() {
      this.$crm.post('organization-branch-school/find-children-by-condition', this.modelSchoolGroupSearch).then(res => {
        this.listSchoolGroup = res.data
        this.totalCount = this.listSchoolGroup.length
      })
    },
    addSchoolGroup() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post('organization-branch-school/create-group', this.modelSchoolGroup).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới tổ thành công',
              },
            })
            this.getListAllSchoolGroup()
            this.$refs['modal-school-group'].hide()
          })
        }
      })
    },
    updateSchoolGroup() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post(`organization-branch-school/update-group/${this.idSchoolGroup}`, this.modelSchoolGroup).then(res => {
            this.modelSchoolBlock = res.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật tổ thành công',
              },
            })
            this.getListAllSchoolGroup()
            this.$refs['modal-school-group'].hide()
          })
        }
      })
    },
    deleteSchoolGroup(item) {
      this.$swal({
        title: 'Xóa tổ',
        text: `Bạn có muốn xóa tổ ${item.organizationBranchName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`organization-branch-school/delete-organization-branch/${item.id}`).then(res => {
            this.getListAllSchoolGroup()
            this.$swal({
              icon: 'success',
              title: 'Xóa tổ',
              text: `Xóa tổ ${item.organizationBranchName} thành công`,
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    closeModal() {
      this.$refs['modal-school-group'].hide()
    },
    getFormCreateSchoolGroup() {
      this.isSchoolGroup = false
      this.modelSchoolGroup = {
        organizationBranchName: '',
        departmentId: null,
        parentId: null,
      }
      this.listSchoolBlock = []
      this.$refs['modal-school-group'].show()
    },
    getFormUpdateSchoolGroup(id) {
      this.isSchoolGroup = true
      this.idSchoolGroup = id
      if (id) {
        this.$crm.post(`organization-branch-school/find-group-by-id/${id}`).then(res => {
          this.modelSchoolGroup = res.data
          this.modelSchoolGroup.parentId = this.modelSchoolGroup.groupId
          this.getListSchoolBlock(this.modelSchoolGroup.departmentId)
          this.$refs['modal-school-group'].show()
        })
      }
    },
    getListSchoolBlockFormModal(id) {
      this.modelSchoolGroup.departmentId = id
      this.listSchoolBlock = []
      if (this.isSchoolGroup === false) {
        this.modelSchoolGroup.parentId = null
      }
      if (this.modelSchoolGroup.departmentId) {
        this.$crm.get(`organization-branch-school/find-children/${this.modelSchoolGroup.departmentId}`)
          .then(res => {
            this.listSchoolBlock = res.data
          })
      }
    },
    getListSchoolBlock(id) {
      this.modelSchoolGroup.departmentId = id
      this.listSchoolBlock = []
      this.modelSchoolGroupSearch.parentId = null
      // this.modelSchoolGroup.parentId = null
      if (this.modelSchoolGroup.departmentId) {
        this.$crm.get(`organization-branch-school/find-children/${this.modelSchoolGroup.departmentId}`)
          .then(res => {
            this.listSchoolBlock = res.data
            this.getListAllSchoolGroup()
          })
      } else {
        this.getListAllSchoolGroup()
      }
    },
    getListAllDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
  },
  computed: {
    rows() {
      if (this.listSchoolGroup) {
        return this.listSchoolGroup.length
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../../@core/scss/custom/group-school.scss';
</style>
